import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as api from "../../../redux/api/stickers/stickers_api";
import * as types from "../../../redux/constants/stickers_constants";
import { Button, Spinner } from "@material-tailwind/react";

function StickerPay({ id }) {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const handleLive = async (e) => {
    e.preventDefault();

    setLoading(true);

    try {
      const response = await api.stickerPayment(id);
      const { error, data } = response;

      if (error) {
        setLoading(false);
        dispatch({ type: types.STICKER_PAYMENT_FAILURE, payload: error });
      } else {
        const firstItem = data.sticker;

        if (firstItem && firstItem.receipt.url) {
          window.location.href = firstItem.receipt.url;
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Button
        color="pink"
        className="flex item-center gap-3"
        onClick={(e) => handleLive(e)}
      >
        {loading && <Spinner color="white" />}
        Make Payment
      </Button>
    </>
  );
}

export default StickerPay;
