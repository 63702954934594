import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as api from "../../../redux/api/dues/dues_api";
import * as types from "../../../redux/constants/dues_constants";

import { Button, Spinner } from "@material-tailwind/react";
import toast from "react-hot-toast";

function RegionDuesButton({ post_type, amount, year, id, data, sub_type }) {
  const [liveLoading, setLiveLoading] = useState(false);

  const dispatch = useDispatch();

  const handleLive = async (e) => {
    e.preventDefault();

    const formData = { post_type, amount, year, id, sub_type };
    setLiveLoading(true);

    try {
      const response = await api.duesPayment(formData);
      const { error, data } = response;

      if (error) {
        setLiveLoading(false);
        toast.error(error);
        dispatch({ type: types.DUES_PAYMENT_FAILURE, payload: error });
      } else {
        const firstItem = data.dues.find((item) => item.year === year);

        const matchingReceipt = firstItem.receipts.find(
          (receiptItem) =>
            receiptItem.status === 2 &&
            receiptItem.post_type === post_type &&
            receiptItem.amount === parseInt(amount) &&
            receiptItem.sub_type === sub_type
        );

        if (matchingReceipt && matchingReceipt.url) {
          window.location.href = matchingReceipt.url;
        }
      }
    } catch (error) {
      // window.location.reload();
      console.log(error);
    }
  };

  return (
    <>
      <Button
        color="pink"
        onClick={(e) => handleLive(e)}
        disabled={liveLoading}
      >
        {liveLoading ? <Spinner color="white" /> : "E-Payment"}
      </Button>
    </>
  );
}

export default RegionDuesButton;
