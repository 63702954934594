import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import SectionTitle from "../../../common/shared/SectionTitle";
import ReactToPrint from "react-to-print";
import {
  Button,
  Card,
  CardBody,
  Dialog,
  DialogFooter,
  DialogHeader,
  Spinner,
  Typography,
} from "@material-tailwind/react";
import ChamberPayment from "./ChamberPayment";
import { manualChamber } from "../../../redux/actions/chamber_actions";
import moment from "moment";
import logo from "../../../assets/img/gba-logo.png";

function ChamberInvoice() {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const location = useLocation();
  const dispatch = useDispatch();

  const currentDate = new Date().getFullYear();

  const handleOpen = () => setOpen(!open);

  let cardBodyRef = useRef();
  const chamberData = location.state?.formData.data;

  const handleManual = (e) => {
    e.preventDefault();
    const formData = {
      amount: 0,
      year: chamberData.exp_date
        ? new Date(chamberData.exp_date).getFullYear()
        : currentDate,
      chamberId: chamberData.id,
    };

    setLoading(true);
    dispatch(manualChamber(formData)).finally(() => {
      setLoading(false);
      setOpen(true);
    });
  };

  const serviceCharge = chamberData?.exp_date
    ? parseFloat(chamberData?.product?.meta?.renewal * 0.02).toFixed(2)
    : parseFloat(chamberData?.product?.meta?.application * 0.02).toFixed(2);

  const manualCharge = chamberData?.exp_date
    ? parseFloat(chamberData?.product?.meta?.renewal * 0.03).toFixed(2)
    : parseFloat(chamberData?.product?.meta?.application * 0.03).toFixed(2);

  return (
    <div className="my-8 max-w-[1200px] m-auto px-4">
      <SectionTitle title="Chamber Invoice" description="" children={[]} />

      <div className="flex items-center justify-center gap-3 mb-8">
        <div>
          <ReactToPrint
            trigger={() => <Button>Print Invoice</Button>}
            content={() => cardBodyRef}
            removeAfterPrint={false}
          />
        </div>

        <ChamberPayment data={chamberData} />

        <Button color="amber" onClick={(e) => handleManual(e)}>
          {loading ? <Spinner color="white" /> : "Manual Pay"}
        </Button>
      </div>

      <div className="flex items-center justify-center mb-8">
        <Typography variant="h5" color="red">
          Electronic Payment attracts a payment charge of 2% (GH¢
          {serviceCharge})
        </Typography>
      </div>

      <Card>
        <CardBody ref={(el) => (cardBodyRef = el)}>
          <img src={logo} alt="logo" className="h-20" />

          <div className="mt-5 pl-3 flex justify-between">
            <div>
              <Typography variant="h5">Ghana Bar Association</Typography>
              <Typography variant="paragraph">
                H/NO. 5, 2nd Ave. State House Road,
              </Typography>
              <Typography variant="paragraph">
                South-East Ridge, Adjacent to
              </Typography>
              <Typography variant="paragraph">
                Ghana International Press Centre,
              </Typography>
              <Typography variant="paragraph">Accra</Typography>
            </div>

            <div>
              <Typography variant="h5">
                Billed to: {chamberData?.name}
              </Typography>
              <Typography variant="paragraph">
                Email Address: {chamberData?.email}
              </Typography>
              <Typography variant="paragraph">
                Phone #: {chamberData?.telephone}
              </Typography>

              <div className="mt-20">
                <Typography variant="h6" color="gray">
                  Invoice Date
                </Typography>
                <Typography variant="h4">
                  {moment().format("MMMM Do, YYYY")}
                </Typography>
              </div>
            </div>
          </div>

          <div className="mt-24">
            <table className="w-full min-w-max table-auto text-left">
              <thead>
                <tr>
                  <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal leading-none opacity-70"
                    >
                      Description
                    </Typography>
                  </th>
                  <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal leading-none opacity-70"
                    >
                      Qty
                    </Typography>
                  </th>
                  <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal leading-none opacity-70"
                    >
                      Amount
                    </Typography>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="p-4 border-b border-blue-gray-50">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {chamberData?.product?.name} -{" "}
                      {chamberData?.exp_date ? "Renewal" : "Application"}
                    </Typography>
                  </td>

                  <td className="p-4 border-b border-blue-gray-50">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      1
                    </Typography>
                  </td>

                  <td className="p-4 border-b border-blue-gray-50">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {chamberData?.exp_date
                        ? chamberData?.product?.meta?.renewal
                        : chamberData?.product?.meta?.application}
                    </Typography>
                  </td>
                </tr>

                {/* <tr>
                  <td className="p-4 border-b border-blue-gray-50">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      Service Charge
                    </Typography>
                  </td>

                  <td className="p-4 border-b border-blue-gray-50">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      1
                    </Typography>
                  </td>

                  <td className="p-4 border-b border-blue-gray-50">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      {serviceCharge}
                    </Typography>
                  </td>
                </tr> */}

                <tr>
                  <td className="p-4 border-b border-blue-gray-50">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-semibold"
                    >
                      Total
                    </Typography>
                  </td>

                  <td className="p-4 border-b border-blue-gray-50">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      GH¢
                    </Typography>
                  </td>

                  <td className="p-4 border-b border-blue-gray-50">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-semibold"
                    >
                      {parseInt(
                        chamberData?.exp_date
                          ? chamberData?.product?.meta?.renewal
                          : chamberData?.product?.meta?.application
                      )}
                    </Typography>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="mt-40">
            <Typography variant="h4">Thank you!</Typography>
            <Typography variant="paragraph">
              If you encounter any issue related to the invoice you can contact
              us at:
            </Typography>

            <Typography variant="paragraph">
              email: <span className="font-semibold">info@ghanabar.org</span>
            </Typography>
          </div>
        </CardBody>
      </Card>

      {open && (
        <Dialog open={open} handleOpen={handleOpen}>
          <DialogHeader>
            <Typography variant="h5" color="blue-gray">
              Manual Payment Successfully Added
            </Typography>
          </DialogHeader>
          <DialogFooter>
            <Button variant="text" color="blue-gray" onClick={handleOpen}>
              Close
            </Button>
          </DialogFooter>
        </Dialog>
      )}
    </div>
  );
}

export default ChamberInvoice;
