import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as api from "../../../redux/api/dues/dues_api";
import * as types from "../../../redux/constants/dues_constants";

import { Button, Spinner } from "@material-tailwind/react";
import toast from "react-hot-toast";

function DuesPayment({ post_type, amount, year, id, data, sub_type = null }) {
  const [liveLoading, setLiveLoading] = useState(false);

  const dispatch = useDispatch();

  const handleLive = async (e) => {
    e.preventDefault();

    const formData = { post_type, amount, year, id, sub_type };
    setLiveLoading(true);

    try {
      const response = await api.duesPayment(formData);
      const { error, data } = response;

      if (error) {
        setLiveLoading(false);
        toast.error(error);
        dispatch({ type: types.DUES_PAYMENT_FAILURE, payload: error });
      } else {
        const firstItem = data.dues.find(
          (item) => item.amount === amount && item.year === year
        );

        const foundReceipt = firstItem.receipts.find(
          (item) => item.status === 2 && item.post_type === post_type
        );

        if (foundReceipt && foundReceipt.url) {
          window.location.href = foundReceipt.url;
        }
      }
    } catch (error) {
      // window.location.reload();
      console.log(error);
    }
  };

  return (
    <>
      <Button
        color={data && data.exempt ? "indigo" : "pink"}
        disabled={(data && data.exempt) || liveLoading}
        onClick={(e) => handleLive(e)}
      >
        {data && data.exempt ? (
          "Exempted"
        ) : liveLoading ? (
          <Spinner color="white" />
        ) : (
          "E-Payment"
        )}
      </Button>
    </>
  );
}

export default DuesPayment;
